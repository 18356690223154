import { defineStore } from 'pinia'
import api from '../services/api/axiosConfig'

export const useNotificationsStore = defineStore('notifications', {
  state: (): any => {
    return {
      notificationsList: [],
      loadingList: true,
      notifications: {
        email: {
          name: 'notifications.email.title',
          subtitle: 'notifications.email.subtitle',
          type: 'newsletter',
          isEnabled: false,
        },
        product: {
          name: 'notifications.product.title',
          subtitle: 'notifications.product.subtitle',
          type: 'notification',
          isEnabled: false,
        },
        order: {
          name: 'notifications.orders.title',
          subtitle: 'notifications.orders.subtitle',
          type: 'notification_order_received',
          isEnabled: false,
        },
        system: {
          name: 'notifications.system.title',
          subtitle: 'notifications.system.subtitle',
          type: 'notification_system',
          isEnabled: false,
        },
      },
    }
  },
  actions: {
    async getNotificationsList(page = 1) {
      try {
        this.loadingList = true

        const response: any = await api.get<any>(`v1/notifications?page=${page}`)

        this.notificationsList = response.data

        return response
      } catch (error: any) {
        return error
      } finally {
        this.loadingList = false
      }
    },
    async makeReadNotifs() {
      try {
        const response: any = await api.post<any>(`v1/notifications/read`)
        return response
      } catch (error: any) {
        return error
      }
    },
    async getNewNotifsCount() {
      try {
        const response: any = await api.get<any>(`v1/notifications/new`)
        return response
      } catch (error: any) {
        return error
      }
    },

    async getNotificationsState() {
      try {
        const response: any = await api.get<any>(`v1/customers/notification`)
        this.notifications.product.isEnabled = response.notification
        this.notifications.email.isEnabled = response.email
        this.notifications.order.isEnabled = response.received
        this.notifications.system.isEnabled = response.system

        return response
      } catch (error: any) {
        return error
      }
    },
    async setNotificationsState(details: any) {
      try {
        const response: any = await api.put<any>(`v1/customers/notification`, details)
        console.log(response)

        return response
      } catch (error: any) {
        return error
      }
    },
  },
})
