<template>
  <VaDropdown :offset="[13, 0]" class="notification-dropdown" stick-to-edges :close-on-content-click="false">
    <template #anchor>
      <VaButton preset="secondary" color="textPrimary" @click="makeRead">
        <VaBadge :text="notCount" overlap>
          <VaIconNotification class="notification-dropdown__icon" />
        </VaBadge>
      </VaButton>
    </template>
    <VaDropdownContent class="sm:max-w-[420px] max-h-[320px]">
      <section id="infinite-scroll-custom-target" class="max-h-[320px] p-1 overflow-auto">
        <VaList class="space-y-1 mb-2">
          <h1 v-if="notificationsList?.length === 0" class="w-full p-2 text-[#767c88]">у вас немає сповіщень</h1>
          <VaInfiniteScroll
            v-else
            :disabled="disableLoad"
            scroll-target="#infinite-scroll-custom-target"
            :load="appendRecordsAsyncId"
          >
            <template v-for="(item, index) in notificationsList" :key="index">
              <RouterLink to="/chat">
                <VaListItem :class="['text-base px-2 mb-1 relative', { 'bg-[#f4f6f8] ': item.new }]">
                  <div v-if="item.new" class="w-2 h-2 absolute -top-1 left-[-3px] z-10 bg-[#ab0000] rounded-full" />
                  <VaListItemSection icon class="mx-0 p-0">
                    <VaIcon :name="'message'" color="secondary" />
                  </VaListItemSection>
                  <VaListItemSection class="text-sm">
                    {{ item.data.title.ru }}
                  </VaListItemSection>
                  <VaListItemSection icon class="mx-1 text-xs text-gray-400">
                    {{ item?.created_at }}
                  </VaListItemSection>
                </VaListItem>
              </RouterLink>
              <VaListSeparator class="mx-3" />
            </template>
          </VaInfiniteScroll>
        </VaList>
      </section>
    </VaDropdownContent>
  </VaDropdown>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import VaIconNotification from '../../../icons/VaIconNotification.vue'
import { useNotificationsStore } from '../../../../stores/notifications'

const { t, locale } = useI18n()

const { getNotificationsList, makeReadNotifs, getNewNotifsCount } = useNotificationsStore()

const notificationsList = ref<any>([])
const notCount = ref<number>(0)
const page = ref(1)
const disableLoad = ref(false)

onMounted(async () => {
  const res = await getNotificationsList()
  const resCount = await getNewNotifsCount(getNewNotifsCount)
  notificationsList.value = res.data
  notCount.value = resCount
})

async function makeRead() {
  if (notificationsList.value.length > 0) {
    await makeReadNotifs()
  }
}
async function appendRecordsAsyncId() {
  page.value += 1 // Move to the next page
  const res = await getNotificationsList(page.value)

  if (res.data && res.data.length > 0) {
    notificationsList.value = [...notificationsList.value, ...res.data]
  } else {
    disableLoad.value = true
    page.value -= 1
  }
}
</script>

<style lang="scss" scoped>
.notification-dropdown {
  cursor: pointer;

  .notification-dropdown__icon {
    position: relative;
    display: flex;
    align-items: center;
  }

  .va-dropdown__anchor {
    display: inline-block;
  }
}
</style>
