<template>
  <div class="flex sm text-primary md:text-white items-baseline gap-1">
    <h1 class="font-bold tracking-[5px] text-[28px]">BUYME</h1>
    <span class="text-[12px] tracking-[1.4px] font-bold">постачальники</span>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useColors } from 'vuestic-ui'

const { getColor } = useColors()

const props = withDefaults(
  defineProps<{
    height?: number
    start?: string
    end?: string
  }>(),
  {
    height: 18,
    start: 'primary',
    end: undefined,
  },
)

const colorsComputed = computed(() => {
  return {
    start: getColor(props.start),
    end: getColor(props.end || props.start),
  }
})
</script>
