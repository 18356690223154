import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios'

const getToken = () => {
  const userString = localStorage.getItem('user')
  const user = userString ? JSON.parse(userString) : null

  return user ? user.token : null
}

const getLanguage = () => {
  const lang = localStorage.getItem('lang')
  if (lang == 'gb') {
    return 'uk'
  }
  return lang || 'uk'
}

const api: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
  headers: {
    'Content-Type': "application/json'",
  },
})

api.interceptors.request.use(
  (config) => {
    const token = getToken()
    const lang = getLanguage()

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    config.headers['X-LOCALE'] = lang

    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

api.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  (error: AxiosError) => {
    return Promise.reject(error)
  },
)

export default api
