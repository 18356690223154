{
  "auth": {
    "agree": "I agree to",
    "createAccount": "Create account",
    "createNewAccount": "Create New Account",
    "telephone": "telephone",
    "login": "Login",
    "password": "Password",
    "recover_password": "Recover password",
    "sign_up": "Sign Up",
    "create": "Create account",
    "have_an_account": "Have an account?",
    "forgot_password": "Forgot password?",
    "keep_logged_in": "Keep me logged in",
    "termsOfUse": "Terms of Use.",
    "reset_password": "Reset password",
    "firstname": "First Name",
    "lastname": "Last Name",
    "repeat_password": "Repeat Password",
    "send_code": "Send a code",
    "code": "code"
  },
  "404": {
    "title": "This page’s gone fishing.",
    "text": "If you feel that it’s not right, please send us a message at ",
    "back_button": "Back to orders"
  },
  "typography": {
    "primary": "Primary text styles",
    "secondary": "Secondary text styles"
  },
  "orders": {
    "title": "Orders",
    "select_status": "Select Status",
    "search_types": {
      "archive": "Искать в архиве",
      "ttn": "Номер заказа или ТТН",
      "receiver": "Имя получателя для поиска",
      "receiver_phone": "Номер телефона получателя для поиска",
      "order_id": "Номер заказа для поиска"
    },
    "detail": {
      "products_item": "Товары в заказе",
      "not_full": "Не полный заказ",
      "order_number": "Номер заказу",
      "order_date": "Дата заказу",
      "order_sum": "Сумма заказу",
      "payment_method": "Выберите способ оплаты",
      "delivery_method": "Выберите способ доставки",
      "recipient": "Получатель",
      "telephone": "Номер телефона",
      "order_payment_method": "Метод оплаты",
      "ttn": "ТТН заказа",
      "ttn_return": "ТТН  заказа",
      "delivery_address": "Доставка",
      "comment": "Комментарий при заказе",
      "sum": "Сумма",
      "ttn_sum": "Сумма наложенного платежа",
      "earnings": "Заработок",
      "shelves": "Полка:"
    },

    "versions": "Versions",
    "setupRemoteConnections": "Setup Remote Connections",
    "currentVisitors": "Current Visitors",
    "navigationLayout": "navigation layout",
    "topBarButton": "Top Bar",
    "sideBarButton": "Side Bar"
  },
  "store": {},
  "balance": {
    "title": "Balance information",
    "balance_up": "To replenish",
    "balance_to_pay": "Available for payment",
    "transactions": {
      "title": "Transactions",
      "status_pay": "Replenishment of the balance"
    }
  },
  "payouts": {
    "title": "Payouts"
  },
  "payment_methods": {
    "title": "Payment Methods"
  },
  "products": {
    "title": "Products",
    "sizes": "Sizes",
    "sizes_list": "List Sizes",
    "size": "size",
    "actions": "actions",

    "import": "Import",
    "product": "Product",
    "form": {
      "name": "name",
      "description": "description",
      "model": "model",
      "price": "price",
      "drop_price": "drop price",
      "price_pre_pay": "price pre pay",
      "option": "option",
      "value": "Size",
      "quantity": "quantity",
      "add_image": "Add Image",
      "image": {
        "title": "Upload your photos and videos to better showcase your product.",
        "drop": "drop files here to upload",
        "des": "Upload a video of your product to give potential buyers more information about it. A video can help better demonstrate the features, functionality, and benefits of your product. Supported formats: MP4, AVI, MOV. Maximum file size: 100 MB."
      }
    },
    "tabs": {
      "main": "Main",
      "sizes": "Sizes",
      "photo": "Photo"
    }
  },
  "settings": {
    "title": "Settings",
    "general": "General preferences",
    "confirm": "Confirm Account",
    "theme": "Theme"
  },
  "articles": {
    "title": "Articles",
    "article": "Article"
  },
  "language": {
    "title": "language",
    "brazilian_portuguese": "Português",
    "english": "English",
    "spanish": "Spanish",
    "simplified_chinese": "Simplified Chinese",
    "persian": "Persian"
  },
  "menu": {
    "auth": "Auth",
    "buttons": "Buttons",
    "timelines": "Timelines",
    "orders": "Orders",
    "billing": "Billing",
    "login": "Login",
    "preferences": "Account preferences",
    "balance": "Balance",
    "payments": "Payments",
    "payouts": "Payouts",
    "chat": "Chats",
    "settings": "Application settings",
    "pricing-plans": "Pricing plans",
    "payment-methods": "Payment methods",
    "signup": "Signup",
    "recover-password": "Recover password",
    "recover-password-email": "Recover password email",
    "404": "404",
    "faq": "FAQ",
    "nova-api": "API",
    "nova-sender": "Sender",
    "nova": "Nova Poshta",
    "users": "Users",
    "products": "Products",
    "stores": "The Shops"
  },
  "messages": {
    "all": "See all messages",
    "new": "New messages from {name}",
    "mark_as_read": "Mark As Read"
  },
  "navbar": {
    "messageUs": "Web development inquiries:",
    "repository": "GitHub Repo"
  },
  "notifications": {
    "title": "Notifications you receive",
    "email": {
      "title": "Receive email messages",
      "subtitle": "Newsletters with up-to-date information on new products, promotions, etc"
    },
    "push": {
      "title": "Push Notifications",
      "subtitle": ""
    },
    "product": {
      "title": "Product notifications",
      "subtitle": "Personal recommendations, discounts or seasonal offers"
    },
    "order": {
      "title": "Order Notification",
      "subtitle": "Information about the current order status"
    },
    "system": {
      "title": "System Notifications",
      "subtitle": "Notifications about current updates or changes in the application"
    },
    "all": "See all notifications",
    "less": "See less notifications",
    "mark_as_read": "Mark as read",
    "sentMessage": "sent you a message",
    "uploadedZip": "uploaded a new Zip file with {type}",
    "startedTopic": "started a new topic"
  },
  "user": {
    "balance": "Balance",
    "language": "Change language",
    "logout": "Logout",
    "profile": "Profile",
    "settings": "Settings",
    "billing": "Billing",
    "faq": "FAQ",
    "helpAndSupport": "Help & support",
    "projects": "Projects",
    "account": "Account",
    "explore": "Explore"
  },
  "treeView": {
    "basic": "Basic",
    "icons": "Icons",
    "selectable": "Selectable",
    "editable": "Editable",
    "advanced": "Advanced"
  },
  "chat": {
    "title": "Chat",
    "not_selected": "No chat selected",
    "empty_chats": "У вас поки немає чатів.",
    "sendButton": "Send"
  },
  "apiKeys": {
    "title": "API Keys",
    "listTitle": "List of API Keys",
    "addApiButton": "Add API",
    "saveApiButton": "Save",
    "addApiTitle": "Add API",
    "editApiTitle": "Edit API",
    "description": "Description"
  },
  "spacingPlayground": {
    "value": "Value",
    "margin": "Margin",
    "padding": "Padding"
  },
  "spacing": {
    "title": "Spacing"
  },
  "cards": {
    "cards": "Cards",
    "fixed": "Fixed",
    "floating": "Floating",
    "contentText": "The unique stripes of zebras make them one of the animals most familiar to people.",
    "contentTextLong": "The unique stripes of zebras make them one of the animals most familiar to people. They occur in a variety of habitats, such as grasslands, savannas, woodlands, thorny scrublands, mountains, and coastal hills. Various anthropogenic factors have had a severe impact on zebra populations, in particular hunting for skins and habitat destruction. Grévy's zebra and the mountain zebra are endangered. While plains zebras are much more plentiful, one subspecies, the quagga.",
    "rowHeight": "Row height",
    "title": {
      "default": "Default",
      "withControls": "With controls",
      "customHeader": "Custom header",
      "withoutHeader": "Without header",
      "withImage": "With Image",
      "withTitleOnImage": "With title on image",
      "withCustomTitleOnImage": "With custom title on image",
      "withStripe": "With stripe",
      "withBackground": "With background"
    },
    "button": {
      "main": "Main",
      "cancel": "Cancel",
      "showMore": "Show More",
      "readMore": "Show More"
    },
    "link": {
      "edit": "Edit",
      "setAsDefault": "Set as default",
      "delete": "Delete",
      "traveling": "Traveling",
      "france": "France",
      "review": "Review",
      "feedback": "Leave feedback",
      "readFull": "Read full article",
      "secondaryAction": "Secondary action",
      "action1": "Action 1",
      "action2": "Action 2"
    }
  },
  "colors": {
    "themeColors": "Theme Colors",
    "extraColors": "Extra Colors",
    "gradients": {
      "basic": {
        "title": "Button Gradients"
      },
      "hovered": {
        "title": "Hovered Button Gradients",
        "text": "Lighten 15% applied to an original style (gradient or flat color) for hover state."
      },
      "pressed": {
        "title": "Pressed Button Gradients",
        "text": "Darken 15% applied to an original style (gradient or flat color) for pressed state."
      }
    }
  },
  "tabs": {
    "alignment": "Tabs alignment",
    "overflow": "Tabs overflow",
    "hidden": "Tabs with hidden slider",
    "grow": "Tabs grow"
  },
  "helpAndSupport": "Help & support",
  "aboutVuesticAdmin": "About Vuestic Admin",
  "search": {
    "placeholder": "Search..."
  },
  "buttonSelect": {
    "dark": "Dark",
    "light": "Light"
  },
  "validation": {
    "required": "This field is required"
  },
  "system": {
    "no_data": "no data available",
    "edited": "edited",
    "confirmed": "Confirmed",
    "button": {
      "confirm": "Confirm",
      "back": "Go back",
      "next": "Continue",
      "add": "Add",
      "cancel": "Cancel",
      "edit": "Edit",
      "save": "save"
    },
    "alert": {
      "code": "A code has been sent to your number, please confirm.",
      "phone": {
        "confirmed": "Phone Confirmed"
      }
    }
  }
}
