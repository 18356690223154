import { defineStore } from 'pinia'
import api from '../services/api/axiosConfig'
import router from '../router'

interface User {}

interface AuthState {
  user: User | null
  returnUrl: string | null
}
interface RegisterForm {
  firstname: string
  lastname: string
  telephone: string
  password: string
  password_confirmation: string
  supplier_manager_contact: string
  supplier_np_api_key: string
}

export const useAuthStore = defineStore({
  id: 'auth',
  state: (): AuthState => ({
    user: JSON.parse(localStorage.getItem('user') || 'null'),
    returnUrl: null,
  }),
  actions: {
    async login(telephone: string, password: string) {
      const user = await api.post<any>(`/suppliers/v1/auth/login`, { telephone, password })

      this.user = user

      localStorage.setItem('user', JSON.stringify(user))

      router.push(this.returnUrl || '/orders')
      window.location.reload()
    },
    async register(formData: RegisterForm) {
      const user = await api.post<any>(`/suppliers/v1/auth/register`, formData)

      this.user = user

      localStorage.setItem('user', JSON.stringify(user))

      router.push(this.returnUrl || '/orders')
      window.location.reload()
    },

    async forgotPassword(telephone: string) {
      const res: any = await api.post<any>(`/suppliers/v1/auth/forgotten`, { telephone })
      if (res) router.push({ name: 'recover-password-code', params: { code: res.code, telephone } })

      return res
    },
    async forgotConfirm(data: any) {
      const res: any = await api.post<any>(`/suppliers/v1/auth/forgotten-confirm`, data)

      return res
    },
    logout() {
      this.user = null
      localStorage.removeItem('user')
      router.push('/auth/login')
      window.location.reload()
    },
  },
})
